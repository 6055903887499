import React, { useState, useEffect, useRef } from "react";
import axios from 'axios'
import { Link, useParams, useLocation } from "react-router-dom";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/edit/matchbrackets"; // Addon for matching brackets
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag"; // Addon for auto-closing tags
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/show-hint.css'; // without this css hints won't show
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/xml-fold';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';
import "codemirror/addon/comment/comment";


// Include Babel for JSX transpilation
import Babel from '@babel/standalone';

import { useUser, SignInButton, SignedIn, SignedOut, Protect } from "@clerk/clerk-react"


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CodeMirror from "codemirror";
import Modal from "react-modal";

import AppBuilderErrors from "./AppBuilderErrors"; // Import your error component
import MyTagsInput from "./MyTagsInput";
Modal.setAppElement("#root"); // Set the root element for the modal
const REACT_APP_SERVER_URL='https://srv455569.hstgr.cloud'


const customModalStyles = {
  content: {
    width: "50%",
    height: "50%",
    margin: "auto",
  },
};





function loadBabelStandalone(callback) {
  const script = document.createElement('script');
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-standalone/6.26.0/babel.min.js';
 
  document.head.appendChild(script);
}

loadBabelStandalone()



const AppBuilder = () => {
 


  <SignedOut>
  <p>This content is public. Only signed out users can see this.</p>
</SignedOut>



const { isSignedIn, user, isLoaded } = useUser();





  const [tags, setTags] = useState([]);



  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNames, setPageNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const codeMirrorRef = useRef(null);
  const { id } = useParams();

  const [role, setRole] = useState("")
    


  const [activeTab, setActiveTab] = useState("html"); // Add 'react' as an option
  const [code, setCode] = useState({
    html: "",
    css: "",
    js: "",
    react: "", // Add initial React code state
  });

  const [appProperties, setAppProperties] = useState({
    active: "",
    current_name: "",
    new_name: "",
    short_description: "",
    description: "",
    active: "",
    app_page_id: "",
    sys_id: "",
    history: [],
    hasUnsavedChanges: false,
  });

  const [errors, setErrors] = useState([]); // State to store JavaScript errors



const [announcement, setAnnouncement] = useState({
title: '',
content: ''
});

const deleteApplication = (appId) => {
  if (window.confirm("Are you sure you want to delete this application?")) {

      axios.delete(`http://160.238.36.102:5000/delete/${appId}`)

  
};
}

document.addEventListener("keydown", function (event) {
  // Check if the user pressed Cmd (Mac) or Ctrl (Windows) and the "S" key
  if ((event.metaKey || event.ctrlKey) && event.key === "s") {
    event.preventDefault(); // Prevent the default browser behavior (saving the page)

    event.stopPropagation()
    handleSave()
    return
  }
});

const transpileReactCode = () => {
  try {
    // Transpile JSX code to plain JavaScript using Babel
    return Babel.transform(code.react, { presets: ['react'] }).code;
  } catch (error) {
    console.error('Error transpiling React code:', error);
    // Handle transpilation errors (e.g., by showing an error message to the user)
    return '';
  }
};

const getPreviewContent = () => {
  const transpiledCode = transpileReactCode()

  return `<!DOCTYPE html>
    <html>
      <head>
        <style>${code.css}</style>
      </head>
      <body>
        ${activeTab === 'html' ? code.html : ''}
        <script type="text/javascript">${transpiledCode}</script>
      </body>
    </html>`;
};

  
      // If the page already exists, attempt to update it
      const getAnnouncement = async () => {
      const announcementD= await fetch(
        `${REACT_APP_SERVER_URL}/api/get-announcement/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      if (announcementD.ok) {
       const announcementData  = await announcementD.json()

        setAnnouncement({
          title: announcementData[0].title,
          content: announcementData[0].content
     
        });

      } else {
      
      }
    
    }


    const setupCodeMirrorForJSX = () => {
      // Initialize CodeMirror with JSX mode
      codeMirrorRef.current = CodeMirror.fromTextArea(document.getElementById("code-editor"), {
        mode: "jsx",
        theme: "material",
        lineNumbers: true,
        autoCloseTags: true,
        autoRefresh: true,
      });
  
      codeMirrorRef.current.setValue(code.react);
      codeMirrorRef.current.on("change", (instance) => {
        setCode((prevCode) => ({
          ...prevCode,
          react: instance.getValue(),
        }));
      });
    };
  
    const executeReactCode = () => {
      const transformedCode = Babel.transform(code.react, { presets: ['react'] }).code;
      // Code to execute the transformed code, possibly in an iframe
    };

    const getUserRole = async(user) =>{

      if (!user) return
      var resp = await user.getOrganizationMemberships() 
    
      if (resp){
   
        setRole(resp[0].role)
         console.log('<<<<role:',role)
         return
      }
     
    
    }
     

    useEffect(() => {
    
      if (id) {
     
        loadAppData();
      }
  if (!user )return
    
      getUserRole(user)
  
  
      if (activeTab === 'react') {
        // Set up CodeMirror for JSX
        setupCodeMirrorForJSX();
      } 
   
  
  
      try {
        var modeName = {
          html: "htmlmixed",
          css: "css",
          js: "javascript",
        };
  
        modeName.mode = modeName[activeTab];
        codeMirrorRef.current = CodeMirror.fromTextArea(
          document.getElementById("code-editor"),
          {
            mode: {
              name: modeName.mode,
            },
            linerWrapping: true,
            autoCloseBrackets: true,
            theme: "material",
            lineNumbers: true,
            autoCloseTags: true,
            autoRefresh: true,
            matchBrackets: true,
            foldGutter: true,
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            extraKeys: { "Ctrl-/": "toggleComment", "Cmd-/": "toggleComment" } 
  
          }
        );
  
        codeMirrorRef.current.setValue(code[activeTab]);
  
        codeMirrorRef.current.on("change", (instance) => {
          setCode((prevCode) => ({
            ...prevCode,
            [activeTab]: instance.getValue(),
          }));
        });
  
        // Handle JavaScript runtime errors
        codeMirrorRef.current.on("error", (instance, error) => {
          alert('error')
          setErrors((prevErrors) => [...prevErrors, error.message]);
        });
      } catch (error) {
        console.error("Error initializing CodeMirror:", error);
      }
  
      return () => {
        if (codeMirrorRef.current) {
          codeMirrorRef.current.toTextArea();
        }
      };
    }, [activeTab, user,role,id]);



  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSave = async () => {
    var pageName = appProperties.current_name
      ? appProperties.current_name
      : prompt("Please enter a name");

    if (!pageName) return;

    const savedData = {
      name: pageName,
      html: code.html,
      css: code.css,
      js: code.js,
      app_page_id: pageName.toLowerCase().split(" ").join("-"),
      id: pageName.toLowerCase().split(" ").join("-"),
      short_description: appProperties.short_description
        ? appProperties.short_description
        : "EMPTY",
      description: appProperties.description
        ? appProperties.description
        : "EMPTY",
      sys_id: appProperties.sys_id,
      active: appProperties.active
    };

    if (appProperties.sys_id) {
      // If the page already exists, attempt to update it
      const updateResponse = await fetch(
        `${REACT_APP_SERVER_URL}/api/update/save/${savedData.app_page_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(savedData),
        }
      );

      if (updateResponse.ok) {
        showToast("success", ` ${savedData.app_page_id} Updated!`);
      } else {
        alert("Failed to update page.");
      }
    }

    if (!appProperties.sys_id) {
      try {
        // Attempt to create a new page
        const createResponse = await fetch(`${REACT_APP_SERVER_URL}/api/save`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(savedData),
        });

        if (createResponse.ok) {
          showToast("success", "Application created!");
        } else {
          alert("Failed to save page.");
        }
      } catch (error) {
        console.error("Error saving page:", error);
        alert("Error saving page.");
      }
    }
  };

  function showToast(type, message) {
 
    toast.success(message, {
      position: "top-right",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  function handleSelectChange() {}

  function openModalWithPageNames(pages) {
    setPageNames(pages);
    setIsModalOpen(true);
  }

 
 
 

  const loadAppData = async () => {

    try {
      const response = await fetch(
        `${REACT_APP_SERVER_URL}/api/load-app-data/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const appData = await response.json();

        setCode({
          html: appData[0].html,
          css: appData[0].css,
          js: appData[0].js,
        });

        setAppProperties({
          current_name: appData[0].name,
          new_name: appData[0].name,
          short_description: appData[0].short_description
            ? appData[0].short_description
            : "EMPTY",
          description: appData[0].description
            ? appData[0].description
            : "empty",
          app_page_id: appData[0].app_page_id,
          sys_id: appData[0].sys_id,
          active: appData[0].active
        });

        codeMirrorRef.current.setValue(appData[0][activeTab]);
      } else if (response.status === 404) {
        // Handle the case where app data is not found
      } else {
        // Handle other errors
      }
    } catch (error) {
      console.error("Error loading app data:", error);
      // Handle the error
    }
  };

  const handleLoad = async () => {
    const pageNamesFromDB = await fetch(
      `${REACT_APP_SERVER_URL}/api/load-app-names`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (pageNamesFromDB.ok) {
      const pages = await pageNamesFromDB.json();
      openModalWithPageNames(pages);
    }
  };

  const filteredPageNames = pageNames.filter((page) =>
    page.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function handleChange(evt) {
   
      setAppProperties((prevProperties) => ({
    ...prevProperties,
    [evt.target.id]: evt.target.value,
  }));
    
  getAnnouncement()

  }

  const closeModalAndReload = () => {
    setIsModalOpen(false);
    // Reload the page
    setTimeout(function () {
      window.location.reload();
    }, 1);
  };


  //TO DO Protect component needs to wait until role comes back
  if (role == 'org:admin') {
  return (

    <div>

  <div className="h-100">
     <SignedOut>
              Please sign in to use app builder
              <SignInButton />
            </SignedOut>
            <SignedIn>
              
    <div className="app-builder h-100">
           

           
           
      <div className="panel text-light bg-dark panel-default app-details-panel">
        <div className="panel-body">
          <div className="menu">
            <button onClick={handleSave}>Save</button>
            <button onClick={handleLoad}>Load</button>
          </div>
      </div>

      <div className="panel-heading p-3">
        <h5>App Details</h5>
      </div>
      <ul className="list-group p-3">
    Status: 
        <select
          id="active"
          value={appProperties.active}
          onChange={handleChange}
          className="list-group-item">
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>

    
        <label htmlFor="current_name">Name</label>
        <input
          id="current_name"
          value={appProperties.current_name}
          onChange={handleChange}
          className="list-group-item"
        />


          <label htmlFor="app_page_id">App Page ID</label>
          <input
            id="app_page_id"
            value={appProperties.current_name
              .toLowerCase()
              .split(" ")
              .join("-")}
            className="readonly list-group-item"
            readOnly
          />

          <label htmlFor="short_description">Short Description</label>
          <input
            id="short_description"
            value={appProperties.short_description}
            onChange={handleChange}
            className="list-group-item"
          />

          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={appProperties.description}
            onChange={handleChange}
            rows="10"
            className="list-group-item"
          />

{/* <h1>Tagging Example</h1>
      <MyTagsInput tags={tags} onChange={handleTagsChange} />
      <div>
        <h2>Selected Tags:</h2>
        <ul>
          {tags.map((tag, index) => (
            <li key={index}>{tag}</li>
          ))}
        </ul>
      </div> */}

          {/* <label htmlFor="app-active">Active</label>
          <select value={true} onChange={handleSelectChange}>
            <option value="true">True</option>
            <option value="false">False</option>
          </select> */}

          <div className="invisible">
            <label htmlFor="app-sys-id">Sys ID</label>
            <input
              id="app-sys-id"
              value={appProperties.sys_id}
              className="readonly list-group-item"
              readOnly
            />
          </div>

          <div className="form-group">
            {/* <button className="btn btn-danger mt-4" onClick={() => deleteApplication(appProperties.app_page_id)}>Delete Application</button> */}
       

          </div>
        </ul>
      </div>
    
      <div className="code-editor-panel">
      
        <div className="tabs">
      
        {/* <button
            className={activeTab === "react" ? "active" : ""}
            onClick={() => handleTabChange("react")}
          >
            React
          </button> */}


          <button
            className={activeTab === "html" ? "active" : ""}
            onClick={() => handleTabChange("html")}
          >
            HTML
          </button>
          <button
            className={activeTab === "css" ? "active" : ""}
            onClick={() => handleTabChange("css")}
          >
            CSS
          </button>
          <button
            className={activeTab === "js" ? "active" : ""}
            onClick={() => handleTabChange("js")}
          >
            JavaScript
          </button>
        </div>

        <div className="editor">
          <textarea id="code-editor" style={{ display: "none" }}></textarea>
        </div>
      </div>


     

      {/* Modal for page names */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModalAndReload}
        contentLabel="Page Names"
        style={customModalStyles} // Apply custom styles
      >
        <h2>Page Names</h2>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={closeModalAndReload}>&times;</button>

        <ul>
          {filteredPageNames.map((page) => (
            <Link
              to={`/app-builder/${page.app_page_id}`}
              key={page.app_page_id}
              onClick={closeModalAndReload}
            >
              <li>{page.name}</li>
            </Link>
          ))}
        </ul>
      </Modal>
     
      <div className="code-preview-container w-100">
     
        <div className="code-preview">
     {activeTab != 'react' ? (
  <iframe
  title="Code Preview"
  frameBorder="0"
  width="100%"
  height="100%"
  srcDoc={`<!DOCTYPE html>
    <html>
      <head>
        <style>${code.css}</style>
      </head>
      <body>
        ${code.html}
        <script>${code.js}</script>
      </body>
    </html>
    
  `}
>
  
</iframe>
     ): (
      <iframe
            title="Code Preview"
            frameBorder="0"
            width="100%"
            height="100%"
            srcDoc={getPreviewContent()}
          >
            
          </iframe>
     )}
        
        </div>
                   {/* Render the error component */}
      <AppBuilderErrors errors={errors} />
      </div>
    

    </div>
    </SignedIn>
    </div>
    
    
  </div>
 
  );
 
     }else{
      return (
       <p>You do not have the permissions to use the Application builder.</p>
      )
     }
};




export default AppBuilder;
