import React from 'react';

function Login() {
  return (
    <div>
      <h1>Login Page</h1>
      <p>This is the login page content.</p>
    </div>
  );
}

export default Login;
