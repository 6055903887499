import React from 'react';
import { UserProfile } from "@clerk/clerk-react"

function MyAccount() {
  return (
    <div>

      <UserProfile></UserProfile>

    </div>
  );
}

export default MyAccount;
