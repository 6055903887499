import React from 'react';

function SignUp() {
  return (
    <div>
      <h1>Sign Up Page</h1>
      <p>This is the sign-up page content.</p>
    </div>
  );
}

export default SignUp;
