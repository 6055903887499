import React, { useState, useEffect } from "react";
import serviceImage from './cube.png';
import { Link, useParams, useLocation } from "react-router-dom";



function Services() {

  function loadService(service){
    fetch(`${REACT_APP_SERVER_URL}/api/service/${service.page_id}`) // Fetch data from the root route
    .then(response => response.json())
    .then(data => {
      setServices(data); // Update the state with the fetched data
    })
    .catch(error => {
      console.error('Fetch error:', error);
    });
}

  

  const { id } = useParams();
  const [services, setServices] = useState([]);

 const REACT_APP_SERVER_URL='https://srv455569.hstgr.cloud'

 
  useEffect(() => {
    // Make a GET request to your server's API endpoint
    fetch(`${REACT_APP_SERVER_URL}/api/services`) // Fetch data from the root route
      .then(response => response.json())
      .then(data => {
        setServices(data); // Update the state with the fetched data
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  }, []);

  return (

    <div>



      <div className="container mt-5">
        <div className="text-center">
      <h1>Services</h1>
      <p>Available services from The Widget Factory.</p>
      </div>
      <div className="row justify-content-center">
        {services.map(service => (
         
          <div key={service.id} className="card bg-light p-0 col-lg-5 mt-3 align-items-center mx-2">
             {/* <h6 className="card-header w-100 p-2 m-0 te">{service.category ? service.category : 'General Service'}</h6> */}
           <img src={serviceImage} alt="Service" className="service-image p-2"/>
         
           <div className="card-body w-100 text-center">
            <h5 className="card-title">{service.name}</h5>
            <p class="card-text">{service.short_description}</p>
  
            <hr/>
          
              <Link className="border btn btn-primary w-75 p-2" to={`/service/${service.page_id}`}>Learn more</Link>

            </div>
            

          </div>
        ))}
      </div>
      </div>
    </div>
  );
}

export default Services;
