// AppBuilderErrors.js

import React from "react";

const AppBuilderErrors = ({ errors }) => {
  return (
    <div className="app-builder-errors">
      <h4>Error Console</h4>
      <ul>
        {errors.map((error, index) => (
          <li key={index} className="error">
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AppBuilderErrors;
