import React from 'react';

function SearchBar({ searchQuery, onSearchInputChange }) {
  return (
<div class="input-group input-group-md w-25">
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-lg">🔍</span>
  </div>


      <input
      className="form-control" aria-label="Large" aria-describedby="inputGroup-sizing-sm"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={onSearchInputChange} // Use onChange to trigger on each keystroke
      />
</div>
  );
}

export default SearchBar;
