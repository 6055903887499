import React, { useState, useEffect } from "react";
import ApplicationButtons from './ApplicationButtons';
import ApplicationFilter from './ApplicationFilter'
import { useClerk, useUser, SignOutButton, SignInButton, SignedIn, SignedOut } from "@clerk/clerk-react";

function Applications() {
  const mode = 'admin' // this will later be set via logged in users roles if they are on this page, drives editability of applications
  const { user } = useUser();
  const [role, setRole] = useState("");

  useEffect(() => {
    getUserRole(user);
  }, [user]);

  const getUserRole = async (user) => {
    if (!user) return;
    var resp = await user.getOrganizationMemberships();
    if (resp) {
      setRole(resp[0].role);
   
      return;
    }
  };

if (role == 'org:admin'){
  return (

    <div className="">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2564868739227867"
     crossorigin="anonymous"></script>


      <ApplicationButtons mode='admin'/>
    </div>
  )


}else{
  return (

    <div className="">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2564868739227867"
     crossorigin="anonymous"></script>


      <ApplicationButtons mode='view'/>
    </div>
  )

}

}
export default Applications;

