import React from 'react';
import { Link } from 'react-router-dom';
import serviceImage from './cube.png';
import ApplicationButtons from './ApplicationButtons';
import axios from "axios";
import Loading from './Loading';
function Home() {


  return (
<div>

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2564868739227867"
     crossorigin="anonymous"></script>




      {/* <ApplicationButtons mode="view"/> */}
  
    
      <div className="container p-5">
      <h1>Free Web Apps and Tools for the modern developer</h1>
      Specialized web development tools for the JavaScript developer!
<div className="row">

      <div className="card bg-light p-0 col-lg-3 mt-3 align-items-center mx-2">

      <Link className="btn h-100" to={`/applications`}>
      <img src={serviceImage} alt="Service" className="service-image p-2" />
      <label className="d-block">
        Web Applications
        </label>
        </Link>

        
      </div>
      <div className="card bg-light p-0 col-lg-3 mt-3 align-items-center mx-2">
      <Link className=" btn h-100" to={`/applications`}>
      <img src={serviceImage} alt="Service" className="service-image p-2" />
      <label className="d-block">
        ServiceNow Applications
        </label>
        </Link>
      </div>
      <div className="card bg-light p-0 col-lg-3 mt-3 align-items-center mx-2">
      <Link className="btn h-100" to={`/applications`}>
      <img src={serviceImage} alt="Service" className="service-image p-2" />
      <label className="d-block">
        ServiceNow Widgets
        </label>
        </Link>
      </div>
      </div>
    </div>
</div>
);


}



export default Home;
