import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css"; // Import your global styles
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home"; // Import your home component
import Applications from "./components/Applications";
import Services from "./components/Services"; // Import your services component
import Contact from "./components/Contact"; // Import your contact component
import Login from "./components/Login"; // Import your login component
import SignUp from "./components/SignUp"; // Import your signup component
import MyProfile from "./components/MyProfile"; // Import your my profile component
import MyAccount from "./components/MyAccount"; // Import your my account component
import MyApplications from "./components/MyApplications"; // Import your my applications component
import Membership from "./components/Membership"; // Import your membership component
import Application from "./components/Application"
import AppBuilder from './components/AppBuilder'
import FileUpload from "./components/FileUpload";
import Service from "./components/Service";
import TWFBreadcrumbs from "./components/TGFBreadcrumbs";

// Import your CSS files here
import "./styles/Header.css";
import "./styles/Footer.css";
import './styles/Services.css'; // Import your CSS file for styling
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {



  return (
    <Router>
      <div className="App">
        <Header />
        
        <ToastContainer />
      
        <Routes>
          
          <Route path="/" element={<Home />} />
          <Route path="/applications" element={<Applications/>} />

          <Route path="/services" element={<Services />} />
          <Route path="/service/:id" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/upload-app" element={<FileUpload />} />
          <Route path="/my-applications" element={<MyApplications />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/:id" element={<Application />} />
          <Route path="/application/:id" element={<Application />} />
          <Route path="/app-builder" element={<AppBuilder />} />
          <Route path="/app-builder/:id" element={<AppBuilder />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
