import React, { useState, useEffect } from 'react';

function ApplicationFilter({ onFilterChange }) {
  const [filterActive, setFilterActive] = useState(false);
  const [filterInactive, setFilterInactive] = useState(false);
  const [filterAlphabetical, setFilterAlphabetical] = useState(false);
  const [sortOption, setSortOption] = useState('created'); // Default to 'Created'

  useEffect(() => {
    onFilterChange({ active: filterActive, inactive: filterInactive, alphabetical: filterAlphabetical, sortBy: sortOption });
  }, [filterActive, filterInactive, filterAlphabetical, sortOption, onFilterChange]);

  return (
    <div className="full-width">
       {/* <label><strong>Type</strong></label> */}
      {/* <div>
          <input
          id="web-app-filter"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            
          />
          <label for="web-app-filter" className="pl-2">Web App
        </label>
      </div>

      <div>
          <input
          id="sn-app-filter"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            
          />
          <label for="sn-app-filter" className="pl-2">ServiceNow App
        </label>
      </div>

      <div>
          <input
          id="sn-widget-filter"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            
          />
          <label for="sn-widget-filter" className="pl-2">ServiceNow Widget
        </label>
      </div>

      <div>
          <input
          id="html-template-filter"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            
          />
          <label for="html-template-filter" className="pl-2">HTML Template
        </label>
      </div> */}


      <label><strong>Filter By</strong></label>
      <div>
        
          <input
          id="active-filter"
            type="checkbox"
            checked={filterActive}
            onChange={() => setFilterActive(!filterActive)}
            
          />
          <label for="active-filter" className="pl-2">Active
        </label>
      </div>
      <div>
       
          <input
          id="inactive-filter"
            type="checkbox"
            checked={filterInactive}
            onChange={() => setFilterInactive(!filterInactive)}
          />
         <label for="inactive-filter" className="pl-2"> Inactive
        </label>
      </div>
      <div>
       
          <input
          id="alphabetical-filter"
            type="checkbox"
            checked={filterAlphabetical}
            onChange={() => setFilterAlphabetical(!filterAlphabetical)}
          />
          <label for="alphabetical-filter" className="pl-2">Alphabetical
        </label>
      </div>
      <div>
      <label><strong>Sort By</strong></label>
        <div>
          
            <input
            id="created-filter"
              type="radio"
              name="sortOption"
              value="created"
              checked={sortOption === 'created'}
              onChange={() => setSortOption('created')}
            />
            <label for="created-filter" className="pl-2">Created
          </label>
        </div>
        <div>
         
            <input
            id="updated-filter"
              type="radio"
              name="sortOption"
              value="updated"
              checked={sortOption === 'updated'}
              onChange={() => setSortOption('updated')}
            />
            <label for="updated-filter" className="pl-2">Updated
          </label>
        </div>
      </div>
    </div>
  );
}

export default ApplicationFilter;
