import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useClerk, useUser, SignOutButton, SignInButton, SignedIn, SignedOut } from "@clerk/clerk-react";
import AppSidebar from './Sidebar';
import logo from './cube.png';
import TWFBreadcrumbs from './TGFBreadcrumbs';



function Header() {
  const { user } = useUser();
  const [role, setRole] = useState("");
  const [initials, setInitials] = useState("");
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const navbarRef = useRef(null); // Ref for the Navbar component

  useEffect(() => {
    getUserRole(user);
  }, [user, initials]);

  useEffect(() => {
    // Function to collapse the navbar if the click is outside of it
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target) && expanded) {
        setExpanded(false);
      }
    };

    // Add and clean up the event listener
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [expanded]);

  const getUserRole = async (user) => {
    if (!user) return;
    var resp = await user.getOrganizationMemberships();
    if (resp) {
      setRole(resp[0].role);
      setInitials(getInitials());
      return;
    }
  };

  function getInitials() {
    var ints = "";
    if (!user) return;

    if (user.firstName) {
      ints = user.firstName[user.firstName.length - 1] + user.lastName[user.lastName.length - 1];
    } else {
      ints = user.emailAddresses[0].emailAddress[0];
    }

    return ints.toUpperCase();
  }

  const handleLogout = () => {
    setRole('');
    navigate('/');
  };

  const collapseNav = () => setExpanded(false);

  return (
    
    <Navbar ref={navbarRef} bg="dark" variant="dark" expand="lg" fixed="top" expanded={expanded} onToggle={setExpanded}>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2564868739227867"
     crossorigin="anonymous"></script>

      <div className="container nav-container">
        <Navbar.Brand as={Link} to="/" onClick={collapseNav}>
          <img src={logo} alt="logo" width="40px"/> The Widget Factory
        </Navbar.Brand>
      
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" onClick={collapseNav}>Home</Nav.Link>
            {role === 'org:admin' && <Nav.Link as={Link} to="/app-builder" onClick={collapseNav}>App Builder</Nav.Link>}
            {true == true && <Nav.Link as={Link} to="/applications" onClick={collapseNav}>Applications</Nav.Link>}
            <Nav.Link as={Link} to="/contact" onClick={collapseNav}>Contact</Nav.Link>
            
            <SignedOut>
              <SignInButton onClick={collapseNav}/>
            </SignedOut>

            <SignedIn>
              <NavDropdown title={initials} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/my-account" onClick={collapseNav}>My Account</NavDropdown.Item>
                <NavDropdown.Divider />
                {role === 'org:admin' && <AppSidebar />}
                <NavDropdown.Item onClick={() => { handleLogout(); collapseNav(); }}>
                  <SignOutButton afterSignOutUrl="/">
                    Sign Out
                  </SignOutButton>
                </NavDropdown.Item>
              </NavDropdown>
            </SignedIn>
          </Nav>
        </Navbar.Collapse>
        
      </div>
    </Navbar>
  );
}

export default Header;
