import React, { useState, useEffect } from "react";
import serviceImage from './cube.png';
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";

function Service() {

  const { id } = useParams();

  const [service, setService] = useState([]);
  const REACT_APP_SERVER_URL = 'https://srv455569.hstgr.cloud';

  useEffect(() => {
    setTimeout(function(){

   
    if (id) {
   
      axios.get(`${REACT_APP_SERVER_URL}/api/service/${id}`)
      .then(response => {
               const appData = response.data
      
        setService(appData)

      })

    }
  },3000)
  }, [id]);

  return (

    <div>
      <div className="container mt-5">
        <div className="text-center">
      <h1>{service.name}</h1>
      <p>{service.short_description}</p>
      </div>
      <div className="row justify-content-center">
      
      </div>
      </div>
    </div>
  );
}

export default Service;
