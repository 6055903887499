import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const REACT_APP_SERVER_URL = 'https://srv455569.hstgr.cloud';

function Application() {
  const { id } = useParams();
  const [htmlCode, setHtmlCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [appName, setAppName] = useState("")
  const [appDescription, setAppDescription] = useState("")

  const executeJavaScript = (code) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    iframe.contentWindow.addEventListener("error", (event) => {
      console.error("Error executing JavaScript:", event.message);
    });

    const script = document.createElement("script");
    script.text = code;
    iframe.contentDocument.body.appendChild(script);

    // Clean up the iframe
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 500);
  };

  const loadApp = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_SERVER_URL}/api/load-app-data/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const appData = await response.json();

        setHtmlCode(appData[0].html);
        setCssCode(appData[0].css);
        setJsCode(appData[0].js);
        setAppName(appData[0].short_description);
        setAppDescription(appData[0].description);

        // Execute JavaScript code when it's loaded
        if (appData[0].js) {
          executeJavaScript(appData[0].js);
        }
      } else if (response.status === 404) {
        // Handle the case where app data is not found
      } else {
        // Handle other errors
      }
    } catch (error) {
      console.error('Error loading app data:', error);
      // Handle the error
    }
  };

  useEffect(() => {
    if (id) {
      loadApp();
    }
  }, [id]);

  return (
    <div className="app-window">
      <div className="app-details">
      <h2>{appName}</h2>
     <p>{appDescription}</p>
      </div>
     
 
    <div className="code-preview-container w-100 m-auto">
        <div className="code-preview">
  
          <iframe
            title="Code Preview"
            frameBorder="0"
            width="100%"
            height="100%"
            srcDoc={`<!DOCTYPE html>
              <html>
                <head>
                  <style>${cssCode}</style>
                </head>
                <body>
                  ${htmlCode}
                  <script>${jsCode}</script>
                </body>
              </html>
              
            `}
          >
            
          </iframe>

        </div>
                   {/* Render the error component */}
    
      </div>
      </div>
  );
}

export default Application;
