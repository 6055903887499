import React from 'react';
import logo from './cube.png';

function Footer() {
  return (
    <footer className="footer text-light bg-dark pb-1">
      <div className="footer-content pt-2">
        <div className="footer-logo">
      
         <a className="text-light" href="/">Home </a> |
          <a className="text-light" href="https://facebook.com"> Calendly</a> | 
          <a className="text-light" href="https://twitter.com"> Contact </a> |
          <a className="text-light" href="https://linkedin.com"> LinkedIn</a>
        </div>
 
        <div className="footer-social mb-2">
     
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} The Widget Factory LLC. All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
