import React, { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    const handleIFrameMessage = (e) => {
      if (typeof e.data === 'object') { return; }
      var args = e.data.split(":");
      var iframe;
      if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
      if (!iframe) { return; }
      switch (args[0]) {
        // Add cases here as in the original script
      }

      // Additional logic from the script
    };

    window.addEventListener("message", handleIFrameMessage, false);

    // Logic to modify the iframe src as in the original script

    return () => {
      window.removeEventListener("message", handleIFrameMessage, false);
    };
  }, []);

  return (
    <iframe
      id="JotFormIFrame-240464646239158"
      title="Contact Form black header"
      onLoad={() => window.parent.scrollTo(0,0)}
      allowTransparency="true"
      allowFullScreen="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/240464646239158"
      frameBorder="0"
      style={{ minWidth: '100%', maxWidth: '100%', height: '100vh', border: 'none' }}
      scrolling="no"
    />
  );
};

export default Contact;
