import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import ApplicationFilter from "./ApplicationFilter";
import { Link, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import serviceImage from './cube.png';
import axios from 'axios';
import SearchBar from "./SearchBar";
import TWFBreadcrumbs from "./TGFBreadcrumbs";

import { AiOutlineInfoCircle } from 'react-icons/ai'; // Import the info icon
import { Tooltip } from "react-tooltip";

function ApplicationButtons(props) {
  const { mode, loading, setLoading } = props;
  const REACT_APP_SERVER_URL = 'https://srv455569.hstgr.cloud';
  const [applications, setApplications] = useState([]);
  const [specialPassword, setSpecialPassword] = useState(""); // State to store the entered password
  const [reloadKey, setReloadKey] = useState(0);
  const location = useLocation()
  const [searchQuery, setSearchQuery] = useState("");

  const [filters, setFilters] = useState({
    active: false,
    inactive: false,
    alphabetical: false,
    sortBy: 'created', // Default sorting by 'Created'
  });

  useEffect(() => {
    axios.get(`${REACT_APP_SERVER_URL}/api/load-app-names`)
      .then(response => {
        let filteredApplications = response.data;

        if (filters.active) {
          filteredApplications = filteredApplications.filter(application => application.active == 1);
        }

        if (filters.inactive) {
          filteredApplications = filteredApplications.filter(application => application.active == 0);
        }

        if (filters.alphabetical) {
          filteredApplications.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (filters.sortBy === 'updated') {
          // Sort by 'Updated'
          filteredApplications.sort((a, b) => new Date(b.sys_updated_on) - new Date(a.sys_updated_on));
        }

        if (searchQuery) {

          const lowerCaseQuery = searchQuery.toLowerCase();
          filteredApplications = filteredApplications.filter(application =>
            application.name.toLowerCase().includes(lowerCaseQuery) ||
            application.short_description.toLowerCase().includes(lowerCaseQuery)
          )
        }

        setApplications(filteredApplications);
      })
      .catch(error => {
        console.error('Axios error:', error);
      });

  }, [reloadKey, filters, searchQuery]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteApplication = (appId,event) => {
    
    // Show a prompt to enter the special password
    const enteredPassword = prompt("Enter the special password to delete this application:");

    // Check if the entered password matches the expected password
    if (enteredPassword === "delete_my_app_arsh") {
      // If the passwords match, proceed with the deletion

      //const DELETE_URL = "http://160.238.36.102:5000";
      axios.delete(`${REACT_APP_SERVER_URL}/delete/${appId}`)
        .then(response => {
          if (response.status === 200) {
            alert('Application succesfully deleted')
            console.log(response.data);
            setReloadKey(prevKey => prevKey + 1);
            // Handle the response data as needed (e.g., update state, UI)
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        })
        .catch(error => {
          console.error('Axios error:', error);
        });
    } else {


      // If the passwords do not match, display an error message
      alert("Invalid password. Deletion canceled.");
      event.stopPropagation()
      return
    }
  };

  return (
    <div className="container mt-5 m-2 m-auto padding-left-none" >

<div className="relative-container">
 
      <div className="fixed-heading w-100">
      {/* <TWFBreadcrumbs /> */}
        <div className="">
          <div className="d-flex align-items-center">
         
            <h3 className="text-align-left pr-5 mb-0">Applications</h3>
            <SearchBar searchQuery={searchQuery} onSearchInputChange={handleSearchInputChange} />

          
          </div>
          <hr className="twf-hr"/>
        </div>
      </div>
      </div>
      <div className="mr-5 mt-2 d-flex flex-row">
        <div className="min-width mr-5 w-25 mt-3 w-25">
          <ApplicationFilter onFilterChange={setFilters} />
        </div>


        <div className="row ">
          {applications.filter(application => location.pathname.includes('/applications') ? application.active >= 0 : application.active == 1).map(application => (




<div key={application.app_page_id} className="card bg-light p-0 col-lg-3 mt-3 align-items-center mx-2">
<Link className="text-center text-dark text-decoration-none min-width-100" to={`/${application.app_page_id}`}>
  <img src={serviceImage} alt="Service" className="service-image p-2" />
  <div className="card-body w-100 text-center">
    <div className="card-title d-flex align-items-center justify-content-center min-height-57">
      <h5>{application.name}</h5>
      <AiOutlineInfoCircle className={`ml-2 mb-1 ${application.app_page_id}`} style={{ cursor: 'pointer' }} />
    

      <Tooltip className="z-index-10" anchorSelect={`.${application.app_page_id}`} place="top">
      {application.short_description}
</Tooltip>
    </div>
    <hr />
    <div className="font-weight-bolder mb-3 m-0 w-100">
      {mode !== 'admin' && (
        <Link className="border btn btn-primary w-75 p-2" to={`/${application.app_page_id}`}>Launch</Link>
      )}
      {mode === 'admin' && (
        <div>
          Status: <span className="font-weight-normal">{application.active === 1 ? 'Active' : 'Inactive'}</span>
          <div className="app-button-controls d-flex">
          <Link className="border btn btn-primary w-75 p-2" to={`/${application.app_page_id}`}>Launch</Link>
          <Link className="border btn btn-secondary w-75 p-2" to={`/app-builder/${application.app_page_id}`}>Edit</Link>
          <button className="border btn btn-danger w-75 p-2" onClick={(event) => handleDeleteApplication(application.app_page_id,event)}>Delete</button>
        </div>
        </div>
      )}
    </div>
  </div>
</Link>
</div>


          ))}
        </div>
      </div>
    </div>
  );
}

export default ApplicationButtons;
