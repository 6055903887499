import React, { useState } from 'react';
import axios from 'axios';

// const REACT_APP_SERVER_URL = 'https://srv455569.hstgr.cloud';
const REACT_APP_SERVER_URL = 'https://srv455569.hstgr.cloud:5000';
function FileUpload() {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [xmlData, setXmlData] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!xmlData) {
      alert('Please enter XML data.');
      return;
    }

    try {
      // Using axios for the request
  

      var savedData = {
        name: name,
        type: type,
        xmlData: xmlData
      }


      const response = await fetch(`/api/upload-app`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(savedData),
      });





      if (response.ok) {
        alert('Upload successful');
        // Optionally reset the form fields
        setName('');
        setType('');
        setXmlData('');
      } else {
        console.log(`Upload failed`, response);
      }
    } catch (error) {
      console.error('Failed to submit:', error);
      alert('Upload failed: Network error or server is down.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Type:</label>
        <select value={type} onChange={(e) => setType(e.target.value)} required>
          <option value="">Select a Type</option>
          <option value="Widget">Widget</option>
          <option value="Application">Application</option>
          <option value="UI Script">UI Script</option>
          <option value="UI Page">UI Page</option>
          <option value="Script Include">Script Include</option>
          <option value="Client Script">Client Script</option>
        </select>
      </div>
      <div>
        <label>XML Data:</label>
        <textarea
          value={xmlData}
          onChange={(e) => setXmlData(e.target.value)}
          required
          rows="10"
          style={{ width: '100%' }}
        ></textarea>
      </div>
      <button type="submit">Upload</button>
    </form>
  );
}

export default FileUpload;
